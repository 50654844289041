.App {
  background-color: #eee;
}

Header {
  /* background-color: #ffff88; */
  height: 51px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

Footer {
  /* background-color: #ffff88; */
  height: 36px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.Content {
  margin-top: 51px;
  margin-bottom: 36px;
  padding: 2rem;
  box-sizing: border-box;
  min-height: calc(100vh - 87px);
}

