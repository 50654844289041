header {
	background-color: #fff;
	padding: 1rem;
	border-bottom: solid 1px #ccc;
	box-shadow: 1px 2px 8px #aaa;
	font-weight: 600;
}

.Home {
	cursor: pointer;
}